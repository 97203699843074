import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout cssClass="card">
  <SEO title="Find me elsewhere" ></SEO>
   <ul>
     {/* <li>Twitter</li> */}
     {/* <li>Goodreads</li> */}
     <li> <a href="https://www.patreon.com/sashahavoc">Patreon</a></li>
   </ul>
  </Layout>
)

export default IndexPage
